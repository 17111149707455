export const AuthJoinOrLoginForm = () => import('../../components/auth/join-or-login-form.vue' /* webpackChunkName: "components/auth-join-or-login-form" */).then(c => wrapFunctional(c.default || c))
export const AuthPinModal = () => import('../../components/auth/pin-modal.vue' /* webpackChunkName: "components/auth-pin-modal" */).then(c => wrapFunctional(c.default || c))
export const AuthSso = () => import('../../components/auth/sso.vue' /* webpackChunkName: "components/auth-sso" */).then(c => wrapFunctional(c.default || c))
export const AnniversaryAbout = () => import('../../components/anniversary/about.vue' /* webpackChunkName: "components/anniversary-about" */).then(c => wrapFunctional(c.default || c))
export const AnniversaryBanner = () => import('../../components/anniversary/banner.vue' /* webpackChunkName: "components/anniversary-banner" */).then(c => wrapFunctional(c.default || c))
export const AnniversaryCarousel = () => import('../../components/anniversary/carousel.vue' /* webpackChunkName: "components/anniversary-carousel" */).then(c => wrapFunctional(c.default || c))
export const AnniversaryGallery = () => import('../../components/anniversary/gallery.vue' /* webpackChunkName: "components/anniversary-gallery" */).then(c => wrapFunctional(c.default || c))
export const AnniversaryHeader = () => import('../../components/anniversary/header.vue' /* webpackChunkName: "components/anniversary-header" */).then(c => wrapFunctional(c.default || c))
export const AnniversaryLinks = () => import('../../components/anniversary/links.vue' /* webpackChunkName: "components/anniversary-links" */).then(c => wrapFunctional(c.default || c))
export const AnniversaryNavigation = () => import('../../components/anniversary/navigation.vue' /* webpackChunkName: "components/anniversary-navigation" */).then(c => wrapFunctional(c.default || c))
export const AnniversaryTimeline = () => import('../../components/anniversary/timeline.vue' /* webpackChunkName: "components/anniversary-timeline" */).then(c => wrapFunctional(c.default || c))
export const BannerAd = () => import('../../components/banner/ad.vue' /* webpackChunkName: "components/banner-ad" */).then(c => wrapFunctional(c.default || c))
export const BannerImg = () => import('../../components/banner/img.vue' /* webpackChunkName: "components/banner-img" */).then(c => wrapFunctional(c.default || c))
export const BannerJoinFree = () => import('../../components/banner/join-free.vue' /* webpackChunkName: "components/banner-join-free" */).then(c => wrapFunctional(c.default || c))
export const BannerPromocode = () => import('../../components/banner/promocode.vue' /* webpackChunkName: "components/banner-promocode" */).then(c => wrapFunctional(c.default || c))
export const ButtonAddToCart = () => import('../../components/button/add-to-cart.vue' /* webpackChunkName: "components/button-add-to-cart" */).then(c => wrapFunctional(c.default || c))
export const ButtonDownloadOrPreorder = () => import('../../components/button/download-or-preorder.vue' /* webpackChunkName: "components/button-download-or-preorder" */).then(c => wrapFunctional(c.default || c))
export const ButtonGiftAddShare = () => import('../../components/button/gift-add-share.vue' /* webpackChunkName: "components/button-gift-add-share" */).then(c => wrapFunctional(c.default || c))
export const ButtonOutlineLight = () => import('../../components/button/outline-light.vue' /* webpackChunkName: "components/button-outline-light" */).then(c => wrapFunctional(c.default || c))
export const ButtonPlay = () => import('../../components/button/play.vue' /* webpackChunkName: "components/button-play" */).then(c => wrapFunctional(c.default || c))
export const BuyAllAccess = () => import('../../components/buy/all-access.vue' /* webpackChunkName: "components/buy-all-access" */).then(c => wrapFunctional(c.default || c))
export const BuyNow = () => import('../../components/buy/now.vue' /* webpackChunkName: "components/buy-now" */).then(c => wrapFunctional(c.default || c))
export const BuyPrice = () => import('../../components/buy/price.vue' /* webpackChunkName: "components/buy-price" */).then(c => wrapFunctional(c.default || c))
export const CourseDetails = () => import('../../components/course/details.vue' /* webpackChunkName: "components/course-details" */).then(c => wrapFunctional(c.default || c))
export const CourseDownloadAndMore = () => import('../../components/course/download-and-more.vue' /* webpackChunkName: "components/course-download-and-more" */).then(c => wrapFunctional(c.default || c))
export const CourseEducatorSummary = () => import('../../components/course/educator-summary.vue' /* webpackChunkName: "components/course-educator-summary" */).then(c => wrapFunctional(c.default || c))
export const CourseFreeLessons = () => import('../../components/course/free-lessons.vue' /* webpackChunkName: "components/course-free-lessons" */).then(c => wrapFunctional(c.default || c))
export const CoursePage = () => import('../../components/course/page.vue' /* webpackChunkName: "components/course-page" */).then(c => wrapFunctional(c.default || c))
export const CoursePageOld = () => import('../../components/course/page_old.vue' /* webpackChunkName: "components/course-page-old" */).then(c => wrapFunctional(c.default || c))
export const CourseRecommendations = () => import('../../components/course/recommendations.vue' /* webpackChunkName: "components/course-recommendations" */).then(c => wrapFunctional(c.default || c))
export const CourseTocAccordion = () => import('../../components/course/toc-accordion.vue' /* webpackChunkName: "components/course-toc-accordion" */).then(c => wrapFunctional(c.default || c))
export const CourseTocList = () => import('../../components/course/toc-list.vue' /* webpackChunkName: "components/course-toc-list" */).then(c => wrapFunctional(c.default || c))
export const EducatorBandsintown = () => import('../../components/educator/bandsintown.vue' /* webpackChunkName: "components/educator-bandsintown" */).then(c => wrapFunctional(c.default || c))
export const EducatorChannel = () => import('../../components/educator/channel.vue' /* webpackChunkName: "components/educator-channel" */).then(c => wrapFunctional(c.default || c))
export const EducatorCourses = () => import('../../components/educator/courses.vue' /* webpackChunkName: "components/educator-courses" */).then(c => wrapFunctional(c.default || c))
export const EducatorHero = () => import('../../components/educator/hero.vue' /* webpackChunkName: "components/educator-hero" */).then(c => wrapFunctional(c.default || c))
export const EducatorInstagram = () => import('../../components/educator/instagram.vue' /* webpackChunkName: "components/educator-instagram" */).then(c => wrapFunctional(c.default || c))
export const EducatorItj = () => import('../../components/educator/itj.vue' /* webpackChunkName: "components/educator-itj" */).then(c => wrapFunctional(c.default || c))
export const EducatorLive = () => import('../../components/educator/live.vue' /* webpackChunkName: "components/educator-live" */).then(c => wrapFunctional(c.default || c))
export const EducatorPage = () => import('../../components/educator/page.vue' /* webpackChunkName: "components/educator-page" */).then(c => wrapFunctional(c.default || c))
export const EducatorPanel = () => import('../../components/educator/panel.vue' /* webpackChunkName: "components/educator-panel" */).then(c => wrapFunctional(c.default || c))
export const EducatorPrivateLessons = () => import('../../components/educator/private-lessons.vue' /* webpackChunkName: "components/educator-private-lessons" */).then(c => wrapFunctional(c.default || c))
export const EducatorRaves = () => import('../../components/educator/raves.vue' /* webpackChunkName: "components/educator-raves" */).then(c => wrapFunctional(c.default || c))
export const EducatorSongs = () => import('../../components/educator/songs.vue' /* webpackChunkName: "components/educator-songs" */).then(c => wrapFunctional(c.default || c))
export const EducatorSpotlight = () => import('../../components/educator/spotlight.vue' /* webpackChunkName: "components/educator-spotlight" */).then(c => wrapFunctional(c.default || c))
export const EducatorYotpo = () => import('../../components/educator/yotpo.vue' /* webpackChunkName: "components/educator-yotpo" */).then(c => wrapFunctional(c.default || c))
export const FormEmail = () => import('../../components/form/email.vue' /* webpackChunkName: "components/form-email" */).then(c => wrapFunctional(c.default || c))
export const FormPromoWithEmail = () => import('../../components/form/promo-with-email.vue' /* webpackChunkName: "components/form-promo-with-email" */).then(c => wrapFunctional(c.default || c))
export const GeneralInfoDownloadTheApp = () => import('../../components/general-info/download-the-app.vue' /* webpackChunkName: "components/general-info-download-the-app" */).then(c => wrapFunctional(c.default || c))
export const GeneralInfoPlans = () => import('../../components/general-info/plans.vue' /* webpackChunkName: "components/general-info-plans" */).then(c => wrapFunctional(c.default || c))
export const GeneralInfoTestimonialsOld = () => import('../../components/general-info/testimonials-old.vue' /* webpackChunkName: "components/general-info-testimonials-old" */).then(c => wrapFunctional(c.default || c))
export const GeneralInfoTestimonials = () => import('../../components/general-info/testimonials.vue' /* webpackChunkName: "components/general-info-testimonials" */).then(c => wrapFunctional(c.default || c))
export const GridCategories = () => import('../../components/grid/categories.vue' /* webpackChunkName: "components/grid-categories" */).then(c => wrapFunctional(c.default || c))
export const GridDynamic = () => import('../../components/grid/dynamic.vue' /* webpackChunkName: "components/grid-dynamic" */).then(c => wrapFunctional(c.default || c))
export const GridThreeRows = () => import('../../components/grid/three-rows.vue' /* webpackChunkName: "components/grid-three-rows" */).then(c => wrapFunctional(c.default || c))
export const ItjAbout = () => import('../../components/itj/about.vue' /* webpackChunkName: "components/itj-about" */).then(c => wrapFunctional(c.default || c))
export const ItjFeatures = () => import('../../components/itj/features.vue' /* webpackChunkName: "components/itj-features" */).then(c => wrapFunctional(c.default || c))
export const ItjPage = () => import('../../components/itj/page.vue' /* webpackChunkName: "components/itj-page" */).then(c => wrapFunctional(c.default || c))
export const ItjRecommendations = () => import('../../components/itj/recommendations.vue' /* webpackChunkName: "components/itj-recommendations" */).then(c => wrapFunctional(c.default || c))
export const ItjTryForFree = () => import('../../components/itj/try-for-free.vue' /* webpackChunkName: "components/itj-try-for-free" */).then(c => wrapFunctional(c.default || c))
export const ModalBasic = () => import('../../components/modal/basic.vue' /* webpackChunkName: "components/modal-basic" */).then(c => wrapFunctional(c.default || c))
export const ModalBuy = () => import('../../components/modal/buy.vue' /* webpackChunkName: "components/modal-buy" */).then(c => wrapFunctional(c.default || c))
export const ModalCollections = () => import('../../components/modal/collections.vue' /* webpackChunkName: "components/modal-collections" */).then(c => wrapFunctional(c.default || c))
export const ModalCourseMore = () => import('../../components/modal/course-more.vue' /* webpackChunkName: "components/modal-course-more" */).then(c => wrapFunctional(c.default || c))
export const ModalEducator = () => import('../../components/modal/educator.vue' /* webpackChunkName: "components/modal-educator" */).then(c => wrapFunctional(c.default || c))
export const ModalGift = () => import('../../components/modal/gift.vue' /* webpackChunkName: "components/modal-gift" */).then(c => wrapFunctional(c.default || c))
export const ModalShare = () => import('../../components/modal/share.vue' /* webpackChunkName: "components/modal-share" */).then(c => wrapFunctional(c.default || c))
export const ModalYoutubeVideo = () => import('../../components/modal/youtube-video.vue' /* webpackChunkName: "components/modal-youtube-video" */).then(c => wrapFunctional(c.default || c))
export const OnlineGuitarLessonsGrid = () => import('../../components/online-guitar-lessons/grid.vue' /* webpackChunkName: "components/online-guitar-lessons-grid" */).then(c => wrapFunctional(c.default || c))
export const PlayerAudioPlayer = () => import('../../components/player/audio-player.vue' /* webpackChunkName: "components/player-audio-player" */).then(c => wrapFunctional(c.default || c))
export const PlayerBreadcrumbs = () => import('../../components/player/breadcrumbs.vue' /* webpackChunkName: "components/player-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const PlayerDescriptionTab = () => import('../../components/player/description-tab.vue' /* webpackChunkName: "components/player-description-tab" */).then(c => wrapFunctional(c.default || c))
export const PlayerEducator = () => import('../../components/player/educator.vue' /* webpackChunkName: "components/player-educator" */).then(c => wrapFunctional(c.default || c))
export const PlayerLessonTabs = () => import('../../components/player/lesson-tabs.vue' /* webpackChunkName: "components/player-lesson-tabs" */).then(c => wrapFunctional(c.default || c))
export const PlayerMaterialsTab = () => import('../../components/player/materials-tab.vue' /* webpackChunkName: "components/player-materials-tab" */).then(c => wrapFunctional(c.default || c))
export const PlayerPage = () => import('../../components/player/page.vue' /* webpackChunkName: "components/player-page" */).then(c => wrapFunctional(c.default || c))
export const PlayerRecommendationsTab = () => import('../../components/player/recommendations-tab.vue' /* webpackChunkName: "components/player-recommendations-tab" */).then(c => wrapFunctional(c.default || c))
export const PlayerSidebar = () => import('../../components/player/sidebar.vue' /* webpackChunkName: "components/player-sidebar" */).then(c => wrapFunctional(c.default || c))
export const PlayerTabsync = () => import('../../components/player/tabsync.vue' /* webpackChunkName: "components/player-tabsync" */).then(c => wrapFunctional(c.default || c))
export const PlayerTocLesson = () => import('../../components/player/toc-lesson.vue' /* webpackChunkName: "components/player-toc-lesson" */).then(c => wrapFunctional(c.default || c))
export const PlayerToc = () => import('../../components/player/toc.vue' /* webpackChunkName: "components/player-toc" */).then(c => wrapFunctional(c.default || c))
export const SearchBarRelative = () => import('../../components/search/bar-relative.vue' /* webpackChunkName: "components/search-bar-relative" */).then(c => wrapFunctional(c.default || c))
export const SearchBar = () => import('../../components/search/bar.vue' /* webpackChunkName: "components/search-bar" */).then(c => wrapFunctional(c.default || c))
export const SearchByTag = () => import('../../components/search/by-tag.vue' /* webpackChunkName: "components/search-by-tag" */).then(c => wrapFunctional(c.default || c))
export const SearchPage = () => import('../../components/search/page.vue' /* webpackChunkName: "components/search-page" */).then(c => wrapFunctional(c.default || c))
export const SearchPagination = () => import('../../components/search/pagination.vue' /* webpackChunkName: "components/search-pagination" */).then(c => wrapFunctional(c.default || c))
export const SearchSortPaginate = () => import('../../components/search/sort-paginate.vue' /* webpackChunkName: "components/search-sort-paginate" */).then(c => wrapFunctional(c.default || c))
export const SearchSort = () => import('../../components/search/sort.vue' /* webpackChunkName: "components/search-sort" */).then(c => wrapFunctional(c.default || c))
export const TestJe = () => import('../../components/test/je.vue' /* webpackChunkName: "components/test-je" */).then(c => wrapFunctional(c.default || c))
export const TextLearnMore = () => import('../../components/text/learn-more.vue' /* webpackChunkName: "components/text-learn-more" */).then(c => wrapFunctional(c.default || c))
export const TextListFloat = () => import('../../components/text/list-float.vue' /* webpackChunkName: "components/text-list-float" */).then(c => wrapFunctional(c.default || c))
export const TextList = () => import('../../components/text/list.vue' /* webpackChunkName: "components/text-list" */).then(c => wrapFunctional(c.default || c))
export const TextTitleBlock = () => import('../../components/text/title-block.vue' /* webpackChunkName: "components/text-title-block" */).then(c => wrapFunctional(c.default || c))
export const TextTooltip = () => import('../../components/text/tooltip.vue' /* webpackChunkName: "components/text-tooltip" */).then(c => wrapFunctional(c.default || c))
export const TextViewMore = () => import('../../components/text/view-more.vue' /* webpackChunkName: "components/text-view-more" */).then(c => wrapFunctional(c.default || c))
export const TfFrame = () => import('../../components/tf/frame.vue' /* webpackChunkName: "components/tf-frame" */).then(c => wrapFunctional(c.default || c))
export const TfGridTable = () => import('../../components/tf/grid-table.vue' /* webpackChunkName: "components/tf-grid-table" */).then(c => wrapFunctional(c.default || c))
export const TfImg = () => import('../../components/tf/img.vue' /* webpackChunkName: "components/tf-img" */).then(c => wrapFunctional(c.default || c))
export const TfYotpoReviews = () => import('../../components/tf/yotpo-reviews.vue' /* webpackChunkName: "components/tf-yotpo-reviews" */).then(c => wrapFunctional(c.default || c))
export const ThumbnailCategory = () => import('../../components/thumbnail/category.vue' /* webpackChunkName: "components/thumbnail-category" */).then(c => wrapFunctional(c.default || c))
export const ThumbnailCourse = () => import('../../components/thumbnail/course.vue' /* webpackChunkName: "components/thumbnail-course" */).then(c => wrapFunctional(c.default || c))
export const ThumbnailIconCaption = () => import('../../components/thumbnail/icon-caption.vue' /* webpackChunkName: "components/thumbnail-icon-caption" */).then(c => wrapFunctional(c.default || c))
export const ThumbnailImgCaptionCircle = () => import('../../components/thumbnail/img-caption-circle.vue' /* webpackChunkName: "components/thumbnail-img-caption-circle" */).then(c => wrapFunctional(c.default || c))
export const ThumbnailImgCaptionCurved = () => import('../../components/thumbnail/img-caption-curved.vue' /* webpackChunkName: "components/thumbnail-img-caption-curved" */).then(c => wrapFunctional(c.default || c))
export const ThumbnailImgCaptionSharp = () => import('../../components/thumbnail/img-caption-sharp.vue' /* webpackChunkName: "components/thumbnail-img-caption-sharp" */).then(c => wrapFunctional(c.default || c))
export const ThumbnailImgRedirect = () => import('../../components/thumbnail/img-redirect.vue' /* webpackChunkName: "components/thumbnail-img-redirect" */).then(c => wrapFunctional(c.default || c))
export const ThumbnailItj = () => import('../../components/thumbnail/itj.vue' /* webpackChunkName: "components/thumbnail-itj" */).then(c => wrapFunctional(c.default || c))
export const ThumbnailProduct = () => import('../../components/thumbnail/product.vue' /* webpackChunkName: "components/thumbnail-product" */).then(c => wrapFunctional(c.default || c))
export const ThumbnailStars = () => import('../../components/thumbnail/stars.vue' /* webpackChunkName: "components/thumbnail-stars" */).then(c => wrapFunctional(c.default || c))
export const VideoPlayer = () => import('../../components/video/player.vue' /* webpackChunkName: "components/video-player" */).then(c => wrapFunctional(c.default || c))
export const VideoVolumeSlider = () => import('../../components/video/volume-slider.vue' /* webpackChunkName: "components/video-volume-slider" */).then(c => wrapFunctional(c.default || c))
export const TfFooterAll = () => import('../../components/tf/footer/all.vue' /* webpackChunkName: "components/tf-footer-all" */).then(c => wrapFunctional(c.default || c))
export const TfFooterInfo = () => import('../../components/tf/footer/info.vue' /* webpackChunkName: "components/tf-footer-info" */).then(c => wrapFunctional(c.default || c))
export const TfFooterNavigation = () => import('../../components/tf/footer/navigation.vue' /* webpackChunkName: "components/tf-footer-navigation" */).then(c => wrapFunctional(c.default || c))
export const TfFooterSummary = () => import('../../components/tf/footer/summary.vue' /* webpackChunkName: "components/tf-footer-summary" */).then(c => wrapFunctional(c.default || c))
export const TfHeaderMidheader = () => import('../../components/tf/header/midheader.vue' /* webpackChunkName: "components/tf-header-midheader" */).then(c => wrapFunctional(c.default || c))
export const TfHeaderNavigation = () => import('../../components/tf/header/navigation.vue' /* webpackChunkName: "components/tf-header-navigation" */).then(c => wrapFunctional(c.default || c))
export const TfHeaderUserbar = () => import('../../components/tf/header/userbar.vue' /* webpackChunkName: "components/tf-header-userbar" */).then(c => wrapFunctional(c.default || c))
export const TfFooterEplusAll = () => import('../../components/tf/footer/eplus/all.vue' /* webpackChunkName: "components/tf-footer-eplus-all" */).then(c => wrapFunctional(c.default || c))
export const TfHeaderEplusAll = () => import('../../components/tf/header/eplus/all.vue' /* webpackChunkName: "components/tf-header-eplus-all" */).then(c => wrapFunctional(c.default || c))
export const TfHeaderEplusMidheader = () => import('../../components/tf/header/eplus/midheader.vue' /* webpackChunkName: "components/tf-header-eplus-midheader" */).then(c => wrapFunctional(c.default || c))
export const TfHeaderEplusNavigationHardcoded = () => import('../../components/tf/header/eplus/navigation-hardcoded.vue' /* webpackChunkName: "components/tf-header-eplus-navigation-hardcoded" */).then(c => wrapFunctional(c.default || c))
export const TfHeaderEplusNavigationMobile = () => import('../../components/tf/header/eplus/navigation-mobile.vue' /* webpackChunkName: "components/tf-header-eplus-navigation-mobile" */).then(c => wrapFunctional(c.default || c))
export const TfHeaderEplusNavigation = () => import('../../components/tf/header/eplus/navigation.vue' /* webpackChunkName: "components/tf-header-eplus-navigation" */).then(c => wrapFunctional(c.default || c))
export const TfHeaderEplusSocials = () => import('../../components/tf/header/eplus/socials.vue' /* webpackChunkName: "components/tf-header-eplus-socials" */).then(c => wrapFunctional(c.default || c))
export const TfHeaderEplusUserbarDropdown = () => import('../../components/tf/header/eplus/userbar-dropdown.vue' /* webpackChunkName: "components/tf-header-eplus-userbar-dropdown" */).then(c => wrapFunctional(c.default || c))
export const TfHeaderEplusUserbar = () => import('../../components/tf/header/eplus/userbar.vue' /* webpackChunkName: "components/tf-header-eplus-userbar" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
